.full-movie-container {
  display: flex;
  color: rgb(153, 170, 187);
  height: 100%;
  position: relative;
  z-index: 3;

  .left-side {
    margin-right: 50px;

    @media(max-width: 767px) {
      margin-top: 40px;
    }

    img {
      border-radius: 4px;
      border: 2px solid rgba(255, 255, 255, 0.21);
      margin-bottom: 20px;
    }

    .button-holder {
      display: flex;
      justify-content: center;

      a {
        svg {
          position: relative;
          top: -2px;
          height: auto;
          width: 24px;
        }
      }
    }
  }

  .right-side {
    @media(max-width: 767px) {
      margin-top: 20px;
    }

    .title {
      font-weight: 700;
      text-shadow: #000 2px 0 5px;
      color: #f3f3f3;
    }

    .circular-chart {
      display: block;
      margin: 10px 0;
      max-width: 80%;
      max-height: 250px;
    }

    .circle {
      fill: none;
      stroke-width: 2.8;
      stroke-linecap: round;
      animation: progress 1s ease-out forwards;

      &.green {
        stroke: #4CC790;
      }

      &.yellow {
        stroke: #ffc107;
      }

      &.red {
        stroke: #dc3545;
      }
    }

    @keyframes progress {
      0% {
        stroke-dasharray: 0 100;
      }
    }

    .percentage {
      fill: var(--main-white);
      font-size: 0.5em;
      text-anchor: middle;
    }

    .returning-series {
      display: inline-block;
      border-radius: 10px;
      padding: 0 10px;
      border: solid 1px #c7a779;
      color: #c7a779;
      margin-top: 5px;
    }

    .date-and-rating {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-bottom: 10px;

      p {
        margin-bottom: 0;
      }
    }

    .director {
      color: var(--main-yellow);
      text-decoration: none;
      transition: 0.3s ease-in-out;

      &:hover {
        color: #c58e21;
      }
    }

    .tagline {
      text-transform: uppercase;
      font-size: 1rem;
      letter-spacing: .075em;
    }

    .overview {
      line-height: 25px;
      margin-bottom: 30px;
    }

    .details-tabs {
      border: 0;
    }

    .nav-tabs .nav-link,
    .nav-tabs .nav-item.show .nav-link {
      color: var(--main-yellow);
      text-transform: uppercase;
      letter-spacing: 0.075em;
      border-color: rgb(39 48 55);
      border-width: 2px;
    }

    .nav-tabs .nav-link.active,
    .nav-tabs .nav-item.show .nav-link {
      background-color: rgb(39 48 55);
      color: #f3f3f3;
      border-bottom-color: rgb(39 48 55);
    }

    .details-holder {
      background: rgb(39 48 55);
      padding: 20px 10px;
      border-radius: 0 4px 4px 4px;
    }

    .cast-list {
      list-style-type: none;
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 30px;
      width: 100%;

      li {
        margin: 4px 2px;

        a {
          // background: #283038;
          padding: 3px 6px;
          border-radius: 4px;
          box-shadow: inset 0 1px 0 hsla(0, 0%, 100%, .05);
          white-space: nowrap;
          text-decoration: none;
          // color: rgb(153, 170, 187);
          background: #4b5866;
          color: #f3f3f3;

          &:hover {
            background: #607388;
          }
        }
      }

      &.tv {
        padding-left: 0;
      }
    }

    .crew-grid {
      color: #f3f3f3;
      margin-bottom: 30px;
      padding-left: 16px;
      width: 100%;

      @media(max-width: 767px) {
        padding: 20px 15px;
      }

      .crew-category {
        display: flex;
        align-items: flex-start;
        margin-bottom: 10px;

        @media(max-width: 767px) {
          display: block;
          padding-bottom: 20px;
        }

        p.job {
          border-bottom: 2px dotted #f3f3f3;
          clear: left;
          float: left;
          width: 15em;

          @media(max-width: 767px) {
            border-bottom: 2px dotted #f3f3f3;
            clear: none;
            float: none;
            width: auto;
            margin-bottom: 20px;
          }

          span {
            display: inline;
            background-color: rgb(39 48 55);
            line-height: 1.1;
            padding: 1px 0.4em 0 0;
            position: relative;
            text-transform: uppercase;
            top: 11px;

            @media(max-width: 767px) {
              padding-right: 5px;
              border-radius: 4px;
              font-weight: bold;
              letter-spacing: 0.075em;
            }
          }
        }

        .name-container {
          padding-left: 10px;
          padding-top: 11px;

          @media(max-width: 767px) {
            padding-left: 5px;
            padding-top: 0;
          }
        }
      }
    }

    .us-providers {
      ul {
        list-style-type: none;
        padding: 0;
        display: flex;
        flex-flow: wrap;

        li {
          margin-right: 15px;
          margin-bottom: 20px;

          img {
            max-width: 50px;
            max-height: 50px;
            border-radius: 8px;
          }
        }
      }
    }

    .genres {
      display: flex;

      ul {
        list-style-type: none;
        padding-left: 10px;
        margin-left: 10px;
        border-left: 1px solid rgb(153, 170, 187);
        ;
      }

      &.shows ul {
        padding-left: 14px;
        margin-left: 35px;
      }
    }

    .divider {
      width: 80px;
      margin-right: 10px;
      border-right: 1px solid rgb(153, 170, 187);
      display: inline-block;
    }

    .collection {
      list-style: none;
      display: flex;
      flex-wrap: wrap;
      padding-left: 0;

      li {
        // width: 140px;
        flex-basis: calc(25% - 10px);
        margin-right: 10px;
        margin-bottom: 10px;
        position: relative;
        overflow: hidden;
        transition: transform 0.3s ease;
        border-radius: 4px;

        @media(max-width: 767px) {
          flex-basis: calc(32% - 10px);
        }

        img {
          width: 100%;
          border: 2px solid rgb(255 255 255 / 21%);
          height: 100%;
        }

        .overlay {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          min-height: 100%;
          background: rgb(0 0 0 / 77%);
          /* Transparent black overlay */
          opacity: 0;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          transition: opacity 0.3s ease;

          .overlay-text {
            color: var(--main-white);
            font-size: 1.2rem;
            font-weight: bold;
            text-align: center;
            padding: 0 8px;
          }

          @media(max-width: 767px) {
            display: none;
          }
        }

        @media(min-width: 768px) {
          &:hover {
            // transform: translateY(-10px);

            .overlay {
              opacity: 1;
            }
          }
        }
      }
    }
  }

  .mobile-container {
    margin-top: 20px;
  }

  .left-mobile {
    padding-top: 10px;

    h1 {
      color: var(--main-white);
      font-weight: bold;
      margin-bottom: 15px;
    }

    .returning-series {
      display: inline-block;
      border-radius: 10px;
      padding: 0 10px;
      border: solid 1px #c7a779;
      color: #c7a779;
      margin-top: 5px;
    }

    .year {
      margin-bottom: 5px;
    }

    .director {
      color: var(--main-yellow);
      text-decoration: none;
    }

    .button-holder {
      display: flex;
      align-items: flex-start;

      .main-button {
        color: var(--main-white);
        text-transform: uppercase;
        font-size: .8em;
        padding: 3px 6px;
        background: #5a6775;
        margin-right: 10px;

        svg {
          position: relative;
          top: -1px;
          width: 10px;
          left: -2px;
        }
      }
    }


  }

  .right-mobile {
    img {
      border-radius: 4px;
      border: 2px solid hsla(0, 0%, 100%, .21);
    }
  }
}