* {
  font-family: 'IBM Plex Sans', sans-serif;
}

::-webkit-scrollbar {
  width: 15px;
}

::-webkit-scrollbar-thumb {
  background: #5d6265;
  border-radius: 15px;
  border: 4px solid #161e24;

  &:hover {
    background: #8b8f91;
  }
}

::-webkit-scrollbar-track {
  background: #0a1016;
}

// buttons 

.main-button {
  text-decoration: none;
  border: none;
  color: #212427;
  padding: 10px 20px;
  font-size: 16px;
  display: inline-block;
  border-radius: 5px;
  font-weight: 600;
  background: var(--main-yellow);
  transition: 0.5s ease;

  &:hover {
    background: #ffd175;
  }
}