footer {
  padding: 20px 0;
  position: absolute;
  left: 0;
  width: 100%;
  background: #2c3440;

  p {
    color: #9ab;
    margin: 0;
  }

  a {
    color: var(--main-yellow);
    text-decoration: none;
  }
}