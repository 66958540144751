.full-list {
  position: relative;
  z-index: 3;

  @media(max-width: 767px) {
    margin-top: 40px;
  }

  .heading {
    color: var(--main-white);
    margin: 0 0 10px;
    padding-left: 7px;
  }

  .movie-list {
    .movie {
      flex-basis: calc(20% - 12px);

      @media(max-width:767px) {
        flex-basis: calc(33.33% - 12px);
      }
    }

    &.crew {
      justify-content: flex-start;
      gap: 10px;

      .movie {
        margin-bottom: 10px;
      }
    }

    &.full-list-movies {
      .movie {
        @media(max-width: 767px) {
          &:last-child {
            display: none;
          }

          &:nth-last-child(2) {
            display: none;
          }
        }
      }

    }
  }
}