.person-info {
  position: relative;
  z-index: 3;
  color: #99aabb;

  .left-side {
    h1 {
      color: var(--main-white);
      font-weight: bold;

      &.tv {
        margin-top: 20px;
        border-top: 1px solid #404347;
        padding-top: 20px;
      }

      span {
        display: block;
        font-size: 1rem;
        text-transform: uppercase;
        letter-spacing: .075em;
        color: #99aabb;
        font-weight: 400;
      }
    }
  }

  .films-and-sort {
    display: flex;
    justify-content: space-between;
    padding-right: 20px;

    @media (max-width: 767px) {
      border-top: 1px solid #99aabb;
      padding-top: 10px;
      margin-top: 15px;
    }

    label {
      margin-right: 8px;
    }

    select {
      background: none;
      color: var(--main-white);
      border: 0;
    }
  }

  .right-side {
    h1 {
      font-weight: bold;
      color: var(--main-white);

      .as-director {
        font-size: 1rem;
        color: #99aabb;
        font-weight: 400;
      }
    }

    img {
      border-radius: 4px;
      margin-bottom: 10px;
      border: 1px solid rgba(255, 255, 255, 0.25);
    }

    .view-more {
      color: #def;
      background: none;
      border: 0;
    }

    @media(max-width: 767px) {
      margin-top: 10px;

      .view-more {
        padding-left: 0;
      }
    }
  }
}