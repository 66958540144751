.top-text-container {
  padding-top: 400px;
  position: relative;
  color: var(--main-white);
  display: flex;
  justify-content: space-between;
  align-items: flex-end;

  @media(max-width: 767px) {
    padding-top: 0;

    h1,
    p {
      display: none;
    }
  }

  h1 {
    font-weight: 700;
    text-shadow: #000 2px 2px 5px;
  }

  p {
    text-shadow: #000 2px 2px 5px;
    margin-bottom: 0;
  }

  .right-side {
    text-align: right;

    @media(max-width:767px) {
      display: none;
    }

    p {
      font-size: 0.9rem;
      color: #9ab;
      padding-right: 7px;
    }
  }
}

.mobile-only-box {
  color: var(--main-white);
  // text-align: center;
  // background-image: url('../../public/chairs.jpg');
  // height: 200px;
  position: relative;
  margin-bottom: -70px;

  h1 {
    font-weight: bold;
    padding: 0 20px;
  }

  p {
    padding: 50px 20px 0;
  }

  @media (min-width:768px) {
    display: none;
  }

  .top-hero-links {
    text-decoration: none;
    color: var(--main-yellow);
  }

  .overlay {
    height: 100%;
    background: rgb(0 0 0 / 77%);
    /* Transparent black overlay */
    display: flex;
    flex-direction: column;
    // align-items: center;
    justify-content: center;
  }
}